<template>
  <v-list-item
    @click="toggleSelected"
    :class="`
      w-100 
      rounded 
      ${backgroundColor} 
      ${border}`"
  >
    <v-list-item-icon>
      <v-simple-checkbox color="success" :value="isSelected" @click="toggleSelected" />
    </v-list-item-icon>
    <v-list-item-content>
      <ModuleAutocompleteItem
        :item="item"
        :isSelected="isSelected"
        :search="search"
        :titleField="titleField"
        :subTitleFields="subTitleFields"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import ModuleAutocompleteItem from "@/components/autocomplete/ModuleAutocompleteItem.vue";

export default {
  components: {
    ModuleAutocompleteItem,
  },
  props: {
    item: Object,
    isSelected: Boolean,
    search: String,
    titleField: Object,
    subTitleFields: Array,
  },
  computed: {
    clientName() {
      return this.product?.client?.name;
    },
    border() {
      return this.isSelected ? "border-a--success--thin" : "border-a";
    },
    backgroundColor() {
      return this.isSelected
        ? "success-super-light--background"
        : "ui-background--background";
    },
  },
  methods: {
    toggleSelected() {
      this.$emit("toggleSelected", { item: this.item });
    },
  },
};
</script>
