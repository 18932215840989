import { withPrefix } from "../util/icon";

export const LIGHT = withPrefix("light", {
  ROUTE: {
    //if route and action. Icons closely tied to routes
    FORM: "ballot-check",
    TASK: "calendar-day",
    EVENT_LOG: "clock-rotate-left",
    TO_DO_LIST: "list",
    OVERVIEW: "circle-info",
    ATTACHMENT: "paperclip",
    PROJECT: "lightbulb-on",
    COMMENT: "messages",
    SIGNATURE: "file-signature",
    PRODUCT: "boxes-stacked",
    READ_CONFIRMATION: "book-open-reader",
    FILE_VERSION_HISTORY: "timeline-arrow fa-rotate-270",
    USER: "user-helmet-safety",
  },
  ACTION: {
    //if only action. icons closely tied to actions
    EDIT: "pen",
    CIRCLE_EDIT: "pen-circle",
    CLEAR: "circle-xmark",
    DELETE: "trash-can",
    PLUS: "plus",
    MINUS: "minus",
    CIRCLE_PLUS: "circle-plus",
    CLOSE: "xmark",
    SEARCH: "magnifying-glass",
    REFRESH: "arrows-rotate",
    SAVE: "folder-arrow-down",
    DOWNLOAD: "download",
    UPLOAD_FILE: "file-arrow-up",
    EXPAND: "expand",
    POP_OUT: "arrow-up-right-from-square",
    FIRST: "backward-step",
    LAST: "forward-step",
    INFO: "circle-info",
    ACCEPT: "check",
    REPEAT: "repeat",
    CUSTOM_COLOR: "eye-dropper",
    CONFIGURE: "gear",
    CATEGORY: "shapes",
    CHART: "chart-line",
    NUMBER: "hashtag",
    FORM_ITEM: "list-check",
    CLIENT: "user-tie",
    EMAIL: "envelope",
    ROLE: "pen-field",
  },
  FILE: {
    FILE_LINKED: "network-wired",
    FILE_PDF: "file-pdf",
    FILE_IMAGE: "file-image",
    FILE_VIDEO: "file-video",
    FILE_WORD: "file-word",
    FILE_EXCEL: "file-excel",
    FILE_POWERPOINT: "file-powerpoint",
    FILE_LINES: "file-lines",
    FILE_SIGNATURE: "file-signature",
    FILE_EXCLAMATION: "file-exclamation",
    FILE_EXPORT: "file-export",
    FILE_INVOICE: "file-invoice",
    FILE: "file",
    FILES: "files",
    FOLDER: "folder",
    FOLDER_TREE: "folder-tree",
    FOLDER_OPEN: "folder-open",
    FOLDER_GEAR: "folder-gear",
    FOLDER_PLUS: "folder-plus",
    FOLDER_LOCK: "$folderLock",
    COPY: "copy",
  },
  COMMON: {
    ANGLE_LEFT: "angle-left",
    ANGLE_RIGHT: "angle-right",
    ANGLE_DOWN: "angle-down",
    PAPER_PLANE_TOP: "paper-plane-top",
    CIRCLE_QUESTION: "circle-question",
    SPINNER: "spinner",
    CIRCLE: "circle",
    EYE: "eye",
    EYE_SLASH: "eye-slash",
    CIRCLE_CHECK: "circle-check",
    STAR: "star",
    STAR_HALF: "star-half",
    TEXT: "text",
    CALENDAR_DAY: "calendar-day",
    WRENCH: "wrench",
    CARET_LEFT: "caret-left",
    CARET_RIGHT: "caret-right",
    CHEVRON_RIGHT: "chevron-right",
    CHEVRON_LEFT: "chevron-left",
    CHEVRON_DOWN: "chevron-down",
    CHEVRON_UP: "chevron-up",
    USER: "user",
    USERS: "users",
    USER_GROUP: "user-group",
    SIGNATURE: "signature",
    CIRCLE_CAMERA: "circle-camera",
    OBJECT_GROUP: "object-group",
    SQUARE: "square",
    SQUARE_CHECK: "square-check",
    SQUARE_MINUS: "square-minus",
    ARROW_LEFT: "arrow-left",
    ARROW_RIGHT: "arrow-right",
    LOCK: "lock",
    CLOCK: "clock",
    BOOK_OPEN_READER: "book-open-reader",
    MEMO_CIRCLE_CHECK: "memo-circle-check",
    TAGS: "tags",
    FLAG: "flag",
    CIRCLE_MINUS: "circle-minus",
    AT: "at",
    BARS: "bars",
    UP_DOWN: "up-down",
    GEAR: "gear",
    CHECK: "check",
    CIRCLE_UP: "circle-up",
    LAYER_GROUP: "layer-group",
    CAR_SIDE: "car-side",
    IMAGE: "image",
    BROWSER: "browser",
    CAMERA: "camera",
    BOLT: "bolt",
    PEN: "pen",
    QUESTION: "question",
  },
});
