<template>
  <div class="w-100 pt-3 pb-3">
    <div class="d-flex align-center" v-if="item.code || hasProductIntegrationEnabled">
      <div class="subtitle--text text-caption" v-if="item.code">{{ item.code }}</div>
      <v-spacer></v-spacer>
      <AppSendtToAccountingChip
        :productUsage="item"
        v-if="hasProductIntegrationEnabled"
      />
    </div>
    <div class="d-flex align-center py-1">
      <div style="font-size: 18px">
        {{ item.name }}
      </div>
      <v-spacer />
      <div class="d-flex align-center">
        <v-simple-checkbox color="primary" class="mr-2" v-model="localIsSelected" />
        <AppToggleMenuBtn left>
          <AppMenuEditBtn @click="$emit('edit')" />
          <AppMenuDeleteBtn @click="$emit('delete')" />
        </AppToggleMenuBtn>
      </div>
    </div>

    <div class="font-weight-bold text-caption">
      {{ client }}
    </div>
    <div class="d-flex text-caption pb-2">
      <div v-if="showPriceRow">{{ priceText }}</div>
      <v-spacer />
      <div>{{ totalPrice }}</div>
    </div>
    <div class="text-caption font-italic pb-2" v-if="item.comment">
      {{ item.comment }}
    </div>
    <div>
      <div class="text-caption subtitle--text">
        {{ $t("common.productFields.addedBy") }}
      </div>
      <div class="d-flex align-end">
        <div>{{ item.user.fullName }}</div>
        <v-spacer />
        <div class="subtitle--text text-caption">
          {{ formattedDate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { billingMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";
export default {
  mixins: [billingMixins],
  props: {
    item: Object,
    isSelected: Boolean,
  },
  computed: {
    localIsSelected: {
      get() {
        return this.isSelected;
      },
      set(value) {
        this.$emit("select", value);
      },
    },
    ...mapGetters("activeIntegrations", {
      hasProductIntegrationEnabled: "hasProductIntegrationEnabled",
    }),
    showPriceRow() {
      return !!this.amount || !!this.unitPrice || !!this.unitValue;
    },
    client() {
      return this.item?.client?.name;
    },
    amount() {
      return this.item?.amount;
    },
    unitPrice() {
      return this.item?.unitPrice;
    },
    unit() {
      return this.item?.unit;
    },
    unitLabel() {
      return this.unit?.label;
    },
    unitValue() {
      return this.unit?.value;
    },
    totalPrice() {
      if (!this.amount || !this.unitPrice) return;
      const price = this.amount * this.unitPrice || null;
      return this.billingMixins_priceFormat(price, "NOK");
    },
    priceText() {
      const hasAmount = !!this.amount;
      const hasUnit = !!this.unitValue;
      const hasUnitPrice = !!this.unitPrice;
      let string = "";
      string += `${hasAmount ? this.amount : 0} `;
      if (hasUnit) {
        string += `${this.unitLabel}`;
      }
      if (hasUnitPrice) {
        const unitPrice = `x ${this.billingMixins_priceFormat(
          this.item.unitPrice,
          "NOK",
        )}`;
        string += ` ${unitPrice}`;
      }
      return string;
    },
    formattedDate() {
      return moment(this.item.createdAt).format("DD.MM.yyyy hh:mm");
    },
  },
};
</script>
