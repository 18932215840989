import { render, staticRenderFns } from "./AppDataTable.vue?vue&type=template&id=fb07e2bc"
import script from "./AppDataTable.vue?vue&type=script&lang=js"
export * from "./AppDataTable.vue?vue&type=script&lang=js"
import style0 from "./AppDataTable.vue?vue&type=style&index=0&id=fb07e2bc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports