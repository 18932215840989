<template>
  <tr
    class="list_tr"
    :class="`${backgroundColor} ${textColor}`"
    @contextmenu="onMenuClick"
    @click="select(!isSelected)"
  >
    <td>
      <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)" />
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.STATUS)">
      <AppTimeEntryStatusIcon
        @click="(event) => $emit('status:click', { ...event, timeEntry: timeEntry })"
        :status="timeEntry.status"
        :loading="timeEntry?.isUpdatingStatus"
      />
    </td>
    <td
      v-if="groupByMixins_showColumn(filtersMixins_dataFields.DATE)"
      class="text-no-wrap"
    >
      {{ new Date(timeEntry.date) | dateFormat("DD.MM.YYYY") }}
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.USER)">
      <div>
        <AppUserAvatar :user="timeEntry.user" show-name :truncate="20" />
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.COMMENT)">
      <div class="table-cell-content__breakable-long-text">
        <span class="d-inline-block"> {{ timeEntry.comment }} </span>
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.IMAGE_IDS)">
      <v-btn v-if="imageIds.length > 0" icon @click.stop="onImagePreviewClick">
        <v-icon>
          {{ $icons.LIGHT.COMMON.IMAGE }}
        </v-icon>
      </v-btn>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.PROJECT)">
      <div v-if="projectId" class="table-cell-content__breakable-long-text">
        <AppActionText
          :textColor="textColor"
          :to="{
            name: $routeNames.PROJECT.VIEW,
            params: { projectId },
          }"
        >
          {{ projectName }}
        </AppActionText>
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.TASK)">
      <div v-if="taskId" class="table-cell-content__breakable-long-text">
        <AppActionText
          :to="{
            name: this.$routeNames.TASK_VIEW.ROOT,
            params: { taskId },
          }"
          :textColor="textColor"
        >
          {{ taskName }}
        </AppActionText>
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.TRANSFERRED)">
      <AppDefaultTooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            :color="timeEntry.transferred ? 'success' : ''"
            :style="timeEntry.transferred ? '' : 'opacity: 40%'"
          >
            {{
              timeEntry.transferred
                ? $icons.SOLID.COMMON.CLOCK
                : $icons.LIGHT.COMMON.CLOCK
            }}
          </v-icon>
        </template>
        <div v-if="timeEntry.transferred">
          {{ $t("timeRegistration.accounting.inAccounting") }}
        </div>
        <div v-else-if="!timeEntry.transferred">
          {{ $t("timeRegistration.accounting.notInAccounting") }}
        </div>
      </AppDefaultTooltip>
    </td>
    <td>
      <div class="table-cell-content__breakable--margin">
        {{ timeEntry.hourType.name }}
        <div class="text-caption" v-if="timeEntry.accountingProductId">
          {{ timeEntry.accountingProduct?.name }}
        </div>
      </div>
    </td>
    <td
      v-if="groupByMixins_showColumn(filtersMixins_dataFields.TIME)"
      class="text-end text-no-wrap"
      :class="`${durationTextColor}`"
    >
      <div v-if="timeEntry.fromTime && timeEntry.toTime">
        {{ timeEntry.fromTime }} - {{ timeEntry.toTime }}
      </div>
    </td>
    <td
      v-if="groupByMixins_showColumn(filtersMixins_dataFields.DURATION)"
      class="text-end text-no-wrap"
      :class="`${durationTextColor}`"
    >
      {{ timeMixins_formatHoursShort(timeEntry.duration) }}
    </td>
    <td
      v-if="groupByMixins_showColumn(filtersMixins_dataFields.BREAK_DURATION)"
      class="text-end text-no-wrap"
      :class="`${durationTextColor}`"
    >
      {{ timeMixins_formatHoursShort(timeEntry.breakDuration) }}
    </td>
    <td class="text-end">
      <v-btn icon @click.stop="onMenuClick">
        <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { groupByMixins, timeMixins } from "@/helpers/mixins";
export default {
  mixins: [timeMixins, groupByMixins],
  props: {
    timeEntry: Object,
    isSelected: Boolean,
    headers: Array,
    tableModel: String,
  },
  computed: {
    projectId() {
      return this.timeEntry?.project?.id;
    },
    taskId() {
      return this.timeEntry?.task?.id;
    },
    isDeclined() {
      return this.timeEntry.status === this.$constant.TIME_ENTRY_STATUS.DECLINED;
    },
    backgroundColor() {
      if (this.isSelected) return "list_tr--selected";
      if (this.isDeclined) return "error-super-light";
      if (this.timeEntry.isExtra) return "secondary-super-light";
      return "";
    },
    durationTextColor() {
      if (this.isDeclined) return "error--text";
      return "subtitle--text";
    },
    textColor() {
      if (this.isDeclined) return "error--text";
      return "";
    },
    projectName() {
      return this.timeEntry.project?.name;
    },
    taskName() {
      return this.timeEntry.task?.name;
    },
    imageIds() {
      return this.timeEntry.imageIds || [];
    },
  },
  methods: {
    onMenuClick(event) {
      this.$emit("menu:click", { timeEntry: this.timeEntry, event });
    },
    onStatusUpdate({ status }) {
      this.$emit("status:update", { status, timeEntry: this.timeEntry });
    },
    select(isSelected) {
      this.$emit("isSelected:change", isSelected);
    },
    onImagePreviewClick() {
      this.$emit("imagePreview:click", { timeEntry: this.timeEntry });
    },
  },
};
</script>
