<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row">
      <div
        style="
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.10000000149011612px;
        "
        class="mb-1"
      >
        {{ $t("statuses.title") }}
      </div>
      <v-spacer />
      <AppSettingsBtn
        @click.stop="onEditStatusesClick"
        :tooltip="statusMixins_tooltip(model)"
      />
    </div>
    <div style="max-height: 360px" class="overflow-y-auto">
      <div v-for="category in statusesIterable" :key="category.category">
        <div class="label--small mt-1">
          {{ $t(`statuses.categories.${category.category}`).toUpperCase() }}
        </div>
        <AppMenuItem
          v-for="status in category.statuses"
          :key="status.id"
          :text="status.name"
          :icon="$icons.SOLID.ACTION.STATUS"
          :iconColor="status.color"
          :isSelected="isSelected(status)"
          @click="selectItem(status)"
          block
          large-icon
        >
        </AppMenuItem>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { statusMixins } from "@/helpers/mixins";

export default {
  mixins: [statusMixins],
  props: {
    model: String,
    statusId: String,
    isLoading: Boolean,
    itemsDisabled: Object,
  },
  computed: {
    ...mapGetters("statuses", {
      getStatusesByModel: "getStatusesByModel",
      getStatusByModelAndId: "getStatusByModelAndId",
    }),
    statuses() {
      return this.getStatusesByModel(this.model);
    },
    statusesIterable() {
      return Object.entries(this.statuses)
        .filter(([category, statuses]) => statuses.length > 0)
        .map(([category, statuses]) => {
          return {
            category,
            statuses,
          };
        });
    },
    status() {
      return this.getStatusByModelAndId({
        model: this.model,
        id: this.statusId,
      });
    },
  },
  methods: {
    selectItem(status) {
      if (!this.isSelected(status)) {
        this.$emit("status:change", status.id);
      }
    },
    isSelected(status) {
      return this.status?.id === status.id;
    },
    onEditStatusesClick() {
      this.$emit("gear:click");
    },
  },
};
</script>
