<template>
  <div class="text-border-reset">
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteTask"
      :title="$tc('taskManagement.deleteConfirmation.title', selectedTasks.length)"
      skip-validation
      @delete="taskDelete"
    />
    <AppDataTableServerSidePagination
      v-model="localSelectedTasks"
      :loading="isLoading"
      :headers="_headers"
      :items="tasks"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noTasks')"
      :columnsToFreeze="columnsToFreeze"
      :groupHeaderColumnsToFreeze="1"
      :isSelectingAll="isSelectingAll"
      @selectAll:click="$emit('selectAll:click')"
      show-select
    >
      <template v-slot:group.header="{ toggle, group, isOpen, headers }">
        <td :colspan="headers.length - 6" @click="toggle">
          <div class="d-flex align-center">
            <v-btn class="mr-4" icon small @click.stop="toggle">
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="6"></td>
      </template>
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppTaskTableItem
          :task="item"
          :showActiveTaskBackground="isActiveTask(item)"
          :headers="headers"
          :tableModel="tableModel"
          :select="select"
          :isSelected="isSelected"
          :isSelectingAll="isSelectingAll"
          @task:update="onTaskUpdate"
          @name:click="onTaskDetailsClick"
          @menu:click="onMenuClick"
          @filters:close="$emit('filters:close')"
          @status:click="onStatusClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppContextMenu ref="contextMenu">
      <AppMenuDetailsBtn @click="onTaskDetailsClick({ task: contextMenuActiveTask })" />
      <AppMenuCopyUrlBtn
        :route="{
          name: $routeNames.TASK_VIEW.ROOT,
          params: { taskId: contextMenuActiveTask?.id },
        }"
      />
      <AppMenuDeleteBtn @click="onTaskDelete" :disabled="!canDeleteSelectedItems" />
    </AppContextMenu>
    <AppChangeCustomStatusIconMenu
      ref="statusMenu"
      @status:change="onStatusChange"
      model="task"
    />
  </div>
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";

export default {
  mixins: [groupByMixins],
  props: {
    tasks: Array,
    isLoading: Boolean,
    taskId: String,
    tableModel: String,
    serverItemsLength: Number,
    selectedTasks: Array,
    isSelectingAll: Boolean,
  },
  data() {
    return {
      dialog: {
        deleteTask: false,
      },
      contextMenuActiveTask: null,
    };
  },
  computed: {
    localSelectedTasks: {
      get() {
        return this.selectedTasks;
      },
      set(value) {
        this.$emit("selectedTasks:change", value);
      },
    },
    columnsToFreeze() {
      return this.filtersMixins_dataTableOptions.groupBy?.includes(
        this.filtersMixins_dataFields.STATUS,
      )
        ? 2
        : 3;
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    projectId() {
      return this.$route.params.projectId;
    },
    headers() {
      return [
        {
          value: this.filtersMixins_dataFields.STATUS,
          sortable: false,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.taskName"),
          align: "start",
          value: this.filtersMixins_dataFields.NAME,
          maxWidth: 200,
          show: true,
        },
        {
          text: "",
          align: "end",
          value: this.filtersMixins_dataFields.COUNT,
          sortable: false,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.responsible"),
          value: this.filtersMixins_dataFields.RESPONSIBLE,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.participants"),
          value: this.filtersMixins_dataFields.COLLABORATORS,
          show: true,
        },

        {
          text: this.$t("common.project"),
          value: this.filtersMixins_dataFields.PROJECT,
          show: !this.projectId,
        },

        {
          text: this.$t("taskManagement.list.belongsToFormItem"),
          value: this.filtersMixins_dataFields.BELONGS_TO_FORM_ITEM,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.startDate"),
          value: this.filtersMixins_dataFields.START_DATE,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.dueDate"),
          value: this.filtersMixins_dataFields.DUE_DATE,
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
    canDeleteSelectedItems() {
      let canDelete = true;

      for (const task of this.localSelectedTasks) {
        if (!task.permissions?.canEdit) {
          canDelete = false;
          break;
        }
      }
      return canDelete;
    },
    activeTask() {
      return this.tasks.find((t) => t.id === this.taskId);
    },
  },
  methods: {
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      return group;
    },
    isActiveTask(task) {
      const isTaskIdInParams = task?.id === this.activeTask?.id;
      return isTaskIdInParams;
    },
    onStatusChange({ statusId, selectedItem }) {
      const body = { statusId };
      this.onTaskUpdate({ body, task: selectedItem });
    },
    onStatusClick({ pointerEvent, x, y, selectedItem }) {
      if (!selectedItem || selectedItem?.isUpdatingStatus) return;

      this.$refs.statusMenu.open({
        pointerEvent,
        x,
        y,
        data: { statusId: selectedItem?.statusId, selectedItem },
      });
    },
    onMenuClick({ event, task }) {
      this.contextMenuActiveTask = task;
      if (!this.localSelectedTasks.some((t) => t.id === task.id)) {
        this.localSelectedTasks = [task];
      }
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onTaskDetailsClick({ task }) {
      this.$emit("task:details", { item: task });
    },
    onTaskUpdate({ body, task }) {
      this.$emit("task:update", { data: body, item: task });
    },
    onTaskDelete() {
      this.dialog.deleteTask = true;
    },
    taskDelete() {
      this.$emit("tasks:delete");
    },
  },
};
</script>
