<template>
  <div>
    <div
      class="align-center d-flex"
      style="
        position: absolute;
        z-index: 200;
        margin: auto;
        top: 10px;
        right: 10px;
        min-height: 40px;
      "
    >
      <AppSubscriptionPlanStar v-if="showStar" class="mr-2" :planCode="planCode" small />
      <AppStonlyGuideBtn
        class="elevation-2 mr-1"
        style="
          background: var(--v-ui-background-base);
          background-color: var(--v-ui-background-base) !important;
          border: thin solid var(--v-primary-border-base) !important;
        "
        v-if="!hasRequiredRoles"
        guideId="zZaRBUmD1u"
      />
      <div v-if="isEditMode">
        <v-btn
          v-if="isEditableWidget"
          :disabled="isLoadingLibrary"
          style="
            background: var(--v-ui-background-base);
            background-color: var(--v-ui-background-base) !important;
            border: thin solid var(--v-primary-border-base) !important;
          "
          class="elevation-2 mr-2"
          icon
          small
          @click.stop.prevent="onEdit"
        >
          <v-icon small>{{ $icons.REGULAR.ACTION.EDIT }}</v-icon>
        </v-btn>
        <v-btn
          :disabled="isLoadingLibrary"
          style="
            background-color: var(--v-ui-background-base) !important;
            border: thin solid var(--v-primary-border-base) !important;
          "
          class="elevation-2"
          icon
          small
          @click.stop.prevent="onDelete"
        >
          <v-icon small color="error">{{ $icons.REGULAR.ACTION.DELETE }}</v-icon>
        </v-btn>
      </div>
    </div>
    <v-img
      v-if="isEditMode"
      style="
        position: absolute;
        z-index: 201;
        height: 30px;
        width: 30px;
        bottom: 1px;
        right: 1px;
      "
      :src="require(`@/assets/resize_bottom_right.svg`)"
    />
    <div @click="onUpgradePlanClick" :class="`h-100 ${cursorPointer}`">
      <v-card
        class="rounded-lg border-a h-100"
        flat
        :loading="isLoading"
        :disabled="isDisabled"
        :color="transparencyClass"
      >
        <div :class="isBlurred ? 'blur' : ''" class="h-100 d-flex flex-column">
          <template v-if="emptyState.isNoData && !isLoading">
            <AppWidgetEmptyState
              :title="emptyState.title"
              :subTitle="emptyState.subTitle"
              :image="emptyState.image"
            />
          </template>
          <template v-else>
            <div
              class="d-flex pt-3 pl-6 pr-3 align-center"
              id="appWidgetTemplateHeaderId"
            >
              <div class="d-flex align-center">
                <div class="text-h6 font-weight-bold pr-1">{{ title | capitalize }}</div>
                <AppStonlyGuideBtn v-if="guideId" :guideId="guideId" />
              </div>
              <v-spacer />
              <slot name="append-header"></slot>
            </div>
            <div class="flex-grow-1" :style="`height: calc(100% - ${headerHeight}px)`">
              <slot></slot>
            </div>
          </template>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import resize_bottom_right from "../../assets/resize_bottom_right.svg";

export default {
  props: {
    title: String,
    isLoading: Boolean,
    guideId: String,
    isEditMode: Boolean,
    isTransforming: Object,
    id: String,
    hasRequiredRoles: Boolean,
    showStar: Boolean,
    planCode: String,
    isEditableWidget: Boolean,
    emptyState: {
      type: Object,
      default: () => ({ isNoData: false }),
    },
  },
  data() {
    return {
      headerHeight: 0,
    };
  },
  computed: {
    transparencyClass() {
      return this.id === this.isTransforming.id && this.isTransforming.val
        ? "rgb(255, 255, 255, 0.6)"
        : "";
    },
    ...mapState("widgetLibrary", {
      isLoadingLibrary: (state) => state.isLoading,
    }),
    isBlurred() {
      return !this.hasRequiredRoles || this.showStar;
    },
    isDisabled() {
      return this.isEditMode || this.showStar || !this.hasRequiredRoles;
    },
    canOpenPlanDialog() {
      return this.showStar && !this.isEditMode;
    },
    cursorPointer() {
      return this.canOpenPlanDialog ? "cursor-pointer" : "";
    },
  },
  methods: {
    onDelete() {
      this.$emit("delete:click");
    },
    onEdit() {
      this.$emit("edit:click");
    },
    getResizeBottomRightIcon() {
      return resize_bottom_right;
    },
    onUpgradePlanClick() {
      if (!this.canOpenPlanDialog) return;
      this.openUpgradePlanDialog();
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.planCode,
      });
    },
  },
  mounted() {
    this.headerHeight = document.getElementById(
      "appWidgetTemplateHeaderId",
    )?.clientHeight;
  },
};
</script>

<style>
.blur {
  filter: blur(2px);
}
.v-card--link:before {
  background: none;
}
</style>
