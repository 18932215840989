<template>
  <div class="list-sticky-footer">
    <v-divider />
    <v-list-item :dense="dense" slot="append-item" @click="onClick">
      <v-list-item-icon>
        <v-icon>{{ $icons.LIGHT.ACTION.CIRCLE_PLUS }}</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="text-body-2">
        <slot></slot>
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    dense: Boolean,
  },
  methods: {
    onClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>
