<template>
  <div>
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteForm"
      :item="selectedItem"
      :title="$t('formManagement.deleteConfirmation.title')"
      :subtitle="$t('formManagement.deleteConfirmation.description')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="formDelete"
    />
    <AppDataTableServerSidePagination
      :loading="isLoading"
      :headers="_headers"
      :items="forms"
      :noDataText="$t('common.noForms')"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :columnsToFreeze="columnsToFreeze"
      :groupHeaderColumnsToFreeze="1"
      disableMultiSelect
    >
      <template v-slot:group.header="{ toggle, group, isOpen, headers }">
        <td :colspan="headers.length - 6" @click="toggle">
          <div class="d-flex align-center">
            <v-btn class="mr-4" icon small @click.stop="toggle">
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="6"></td>
      </template>
      <template v-slot:item="{ item, headers }">
        <AppFormTableItem
          :form="item"
          :selectedFormId="formId"
          :hideProjectColumn="hideProjectColumn"
          :isHighlighted="isHighlighted(item)"
          :headers="headers"
          :tableModel="tableModel"
          @form:update="onFormUpdate"
          @name:click="onFormNameClick"
          @menu:click="onMenuClick"
          @status:click="onStatusClick"
          @signature:click="onSignatureClick"
        />
      </template>
    </AppDataTableServerSidePagination>

    <AppContextMenu ref="contextMenu" @input="isContextMenuOpen = $event">
      <AppMenuFillOutBtn @click="onFormFillOutClick" />
      <AppMenuDetailsBtn @click="onFormDetailsClick" />
      <AppMenuCopyBtn @click="onCopyClick" />
      <AppMenuSendToSignBtn @click="onSendToSignClick" :isSentToSign="isSentToSign" />
      <AppMenuCopyUrlBtn
        :route="{
          name: $routeNames.FORM_VIEW.ROOT,
          params: { formId: selectedItem?.id },
        }"
      />
      <AppMenuDeleteBtn
        @click="onFormDelete"
        :disabled="!selectedItem?.permissions?.canEdit"
      />
    </AppContextMenu>
    <AppChangeCustomStatusIconMenu
      ref="statusMenu"
      @status:change="onStatusChange"
      model="form"
    />
    <AppCopyItemsDialog
      v-model="dialog.copyDialog"
      :inItems="[selectedItem]"
      type="FORM"
      @submit="onCopy"
    />
  </div>
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";

export default {
  mixins: [groupByMixins],

  props: {
    forms: Array,
    serverItemsLength: Number,
    isLoading: Boolean,
    formId: String,
    hideProjectColumn: Boolean,
    tableModel: String,
  },
  data() {
    return {
      statusMenu: {
        selectedForm: null,
        show: false,
        x: null,
        y: null,
      },
      selectedItem: null,
      dialog: {
        deleteForm: false,
        copyDialog: false,
      },
      isContextMenuOpen: false,
    };
  },
  computed: {
    isSentToSign() {
      return !!this.selectedItem?.signatureSession;
    },
    columnsToFreeze() {
      return this.filtersMixins_dataTableOptions.groupBy?.includes(
        this.filtersMixins_dataFields.STATUS,
      )
        ? 1
        : 2;
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          value: this.filtersMixins_dataFields.STATUS,
          sortable: false,
          show: true,
        },
        {
          text: this.$t("formManagement.list.formName"),
          align: "start",
          value: this.filtersMixins_dataFields.NAME,
          show: true,
        },
        {
          text: this.$t("formManagement.list.signatureStatus"),
          value: this.filtersMixins_dataFields.SIGNATURE_STATUS,
          show: true,
        },
        {
          text: this.$t("common.progress"),
          value: this.filtersMixins_dataFields.PROGRESS,
          show: true,
        },
        {
          text: this.$t("formManagement.list.responsible"),
          value: this.filtersMixins_dataFields.RESPONSIBLE,
          show: true,
        },
        {
          text: this.$t("common.project"),
          value: this.filtersMixins_dataFields.PROJECT,
          show: !this.hideProjectColumn,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("formManagement.list.belongsToTask"),
          ),
          value: this.filtersMixins_dataFields.TASK,
          show: true,
        },
        {
          text: this.$t("common.category"),
          value: this.filtersMixins_dataFields.CATEGORY,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.dueDate"),
          value: this.filtersMixins_dataFields.DUE_DATE,
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
  },
  methods: {
    onCopyClick() {
      this.dialog.copyDialog = true;
    },
    onCopy(e) {
      this.$emit("forms:copy", { forms: e });
    },
    onSignatureClick({ form }) {
      this.$emit("signature:click", { form });
    },
    onSendToSignClick() {
      this.$emit("form:sendToSign", { form: this.selectedItem });
    },
    isHighlighted(item) {
      const isCurrentItem = this.formId === item?.id || this.selectedItem === item;
      if (!isCurrentItem) return false;

      const isAnyDialogsOpen = Object.values(this.dialog).some((value) => value === true);
      const isDetailsOpen = item.id === this.formId;

      return this.isContextMenuOpen || isAnyDialogsOpen || isDetailsOpen;
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      if (this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.CATEGORY)) {
        return this.translatedCategory(group);
      }

      return group;
    },
    onStatusChange({ statusId, selectedItem }) {
      const body = { statusId };

      this.onFormUpdate({ body, form: selectedItem });
    },
    onStatusClick({ pointerEvent, x, y, selectedItem }) {
      if (!selectedItem || selectedItem?.isUpdatingStatus) return;

      this.$refs.statusMenu.open({
        pointerEvent,
        x,
        y,
        data: { statusId: selectedItem?.statusId, selectedItem },
      });
    },
    translatedCategory(key) {
      return this.$te(`formManagement.category.${key}`)
        ? this.$t(`formManagement.category.${key}`)
        : key;
    },
    onMenuClick({ event, form }) {
      this.selectedItem = form;
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onFormDetailsClick() {
      this.$emit("form:details", { item: this.selectedItem });
    },
    onFormNameClick({ form }) {
      this.selectedItem = form;
      this.onFormFillOutClick();
    },
    onFormFillOutClick() {
      this.$emit("form:fillOut", { item: this.selectedItem });
    },
    onFormUpdate(e) {
      this.$emit("form:update", e);
    },
    onFormDelete() {
      this.dialog.deleteForm = true;
    },
    formDelete() {
      this.$emit("form:delete", { form: this.selectedItem });
    },
  },
};
</script>
