<template>
  <v-dialog v-model="localDialog" max-width="500px" scrollable>
    <v-card>
      <v-progress-linear
        height="6"
        :active="isLoading"
        :indeterminate="isLoading"
        top
        absolute
      />
      <template v-if="state === 'BEFORE_MOVE'">
        <v-card-title>
          <span class="headline d-flex">
            {{ $t("fileManagement.move.title") }}
            <template v-if="currentFolder">
              <AppTextTruncateValue :text="folderName" :truncate="25" />
            </template>
          </span>
          <v-spacer />
          <AppStonlyGuideBtn guideId="4Sz9lN0Ald" />
        </v-card-title>
        <v-card-text>
          <AppDefaultTextField
            ref="search"
            class="pb-3"
            :label="$t('common.search')"
            :append-icon="$icons.LIGHT.ACTION.SEARCH"
            v-model="search"
            hide-details
            autofocus
            clearable
          />
          <AppFolderPath
            :prependPath="storagePrependPath"
            :truncatedPath="storageTruncatedPath"
            @item:click="onPathItemClick"
          />
        </v-card-text>
        <v-divider />
        <v-card-text
          class="overflow-y-auto h-400px"
          :class="[{ 'pb-0': hasDisabledFolders }]"
        >
          <template v-if="!isLoading">
            <template v-if="filteredStorage.length > 0">
              <div v-for="storage of filteredStorage" :key="storage.id">
                <AppStorageItem :item="storage" @item:click="onFolderClick">
                  <template v-slot:prepend="{ item }">
                    <AppStorageThumbnail :disabled="item.isDisabled" :item="item" top />
                  </template>
                  <template v-slot:label="{ item, translation }">
                    <div class="pl-3 h-100 d-flex align-center">
                      <span
                        class="text--primary"
                        :class="[{ 'text--disabled': item.isDisabled }]"
                      >
                        <AppTextTruncateValue
                          :text="translation || item.name"
                          :truncate="40"
                        />
                      </span>
                    </div>
                  </template>
                  <template v-slot:append="{ item }">
                    <div class="h-100 d-flex align-center">
                      <v-btn
                        icon
                        @click="onFolderClick(item)"
                        :disabled="storage.isDisabled"
                      >
                        <v-icon>{{ $icons.LIGHT.COMMON.CHEVRON_RIGHT }}</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </AppStorageItem>
              </div>
            </template>
            <template v-else>
              <div class="w-100 h-100 d-flex align-center justify-center">
                <v-row
                  class="fill-height ma-0d-flex mw-250px"
                  justify="center"
                  align-content="center"
                >
                  <v-col class="text-center pb-0" cols="12">
                    <h2>{{ $t("fileManagement.move.folderEmpty.title") }}</h2>
                  </v-col>
                </v-row>
              </div>
            </template>
          </template>
        </v-card-text>

        <v-spacer />
        <v-card-text v-if="hasDisabledFolders">
          <v-alert
            class="ma-0"
            type="info"
            dense
            text
            outlined
            :icon="$icons.LIGHT.ACTION.INFO"
          >
            {{ $t("fileManagement.move.cantMove") }}
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <AppDialogActionBtnPair
            @confirm="onSave"
            @cancel="onCancel"
            :confirmText="$t('fileManagement.move.moveHere')"
            :disabled="!canMove"
            :loading="isMoving"
          />
          <v-spacer />
          <span>
            {{ $tc("fileManagement.move.toMove", selectedStorages.length) }}
          </span>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text class="d-flex flex-column align-center">
          <v-img
            class="mt-15 mb-10"
            contain
            max-height="250"
            max-width="270"
            src="https://go-digital.azureedge.net/assets/images/success.png"
          />
          <h3 class="mb-10">{{ $t("fileManagement.move.success.title") }}</h3>
          <v-alert text type="warning" v-if="rejected > 0">
            {{ $tc("fileManagement.move.success.rejected", rejected) }}
          </v-alert>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="d-flex flex-column align-center">
          <v-btn text @click="onCancel" color="primary-link">
            {{ $t("common.close") }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { storageMixins } from "@/helpers/mixins";
export default {
  mixins: [storageMixins],
  data() {
    return {
      state: "BEFORE_MOVE",
      rejected: 0,
      hasDisabledFolders: false,
      search: null,
    };
  },
  props: {
    dialog: Boolean,
    selectedStorages: {
      type: Array,
      default: () => [],
    },
    permissionLevel: String,
    parentId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    storages: {
      handler(storages) {
        const hasDisabledFolders = storages.find((x) => x.isDisabled === true);
        this.hasDisabledFolders = hasDisabledFolders ? true : false;
      },
      immediate: true,
      deep: true,
    },
    dialog: {
      handler(value) {
        if (value) {
          this.onPathNavigate({ id: this.parentId });
        }
      },
    },
  },
  computed: {
    folderName() {
      return `\u00A0${this.currentFolder.text}`;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("storageMove", {
      storages: (state) => state.items,
      path: (state) => state.path,
      currentFolder: (state) => state.currentFolder,
      isMoving: (state) => state.isMoving,
      storagePrependPath: (state) => state.prependPath,
      storageTruncatedPath: (state) => state.truncatedPath,
    }),
    ...mapGetters("storageMove", {
      isLoading: "isLoading",
    }),
    canMove() {
      return this.currentFolder ? true : false;
    },
    filteredStorage() {
      const selectedIds = this.selectedStorages.map((i) => i.id);
      const filtered = this.storages.filter((s) => {
        return (
          !selectedIds.includes(s.id) &&
          s.name.toLowerCase().includes(this.search?.toLowerCase() || "")
        );
      });
      return filtered;
    },
  },
  methods: {
    getFolders(id, selected, permissionLevel) {
      this.$store.dispatch("storageMove/getFolders", {
        id,
        selected,
        permissionLevel,
      });
    },
    getPath(id) {
      this.$store.dispatch("storageMove/getPath", id);
    },
    onFolderClick(item) {
      if (!item.isDisabled) {
        this.onPathNavigate(item);
      }
    },
    onPathItemClick(item) {
      this.onPathNavigate(item);
    },
    onPathNavigate(item) {
      this.search = null;
      this.getFolders(item.id, this.selectedStorages, this.permissionLevel);
      this.getPath(item.id);

      if (this.$refs.search && !this.$refs.search.isFocused) {
        this.$refs.search.focus();
      }
    },
    onCancel() {
      this.localDialog = false;
      this.state = "BEFORE_MOVE";
      this.$store.commit("storageMove/resetPaths");
      this.$store.commit("storageMove/setCurrentFolder", null);
    },
    async onSave() {
      if (this.currentFolder) {
        const selectedStorageIds = this.selectedStorages.map((x) => x.id);
        const folder = this.currentFolder.id;
        const response = await this.$store.dispatch("storageMove/moveStorages", {
          storages: selectedStorageIds,
          folder,
          permissionLevel: this.permissionLevel,
        });
        if (response) {
          this.rejected = response.rejected;
          this.state = "AFTER_MOVE";
          this.$emit("after:save");
        }
      }
    },
  },
};
</script>
