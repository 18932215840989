<template>
  <div>
    <AppContextMenu ref="actionMenu">
      <AppMenuItem
        @click="onOpenTask"
        :icon="$icons.REGULAR.ACTION.POP_OUT"
        :text="$t('taskManagement.taskMenu.openTask')"
      />
      <AppMenuItem
        @click="onChangeTaskClick"
        :icon="$icons.REGULAR.ACTION.SWITCH"
        :text="$t('taskManagement.taskMenu.changeTask')"
      />
      <AppMenuItem
        @click="onClearTask"
        :icon="$icons.REGULAR.ACTION.NO_TASK"
        :text="$t('taskManagement.taskMenu.removeTask')"
      />
    </AppContextMenu>
    <AppContextMenu
      ref="taskPickerMenu"
      :closeOnContentClick="false"
      hide-default-padding
      backgroundColor="transparent"
      contentClass="v-menu__content--input"
    >
      <div style="height: 41px">
        <AppTaskAutocomplete
          ref="autocomplete"
          v-model="localTask"
          isReadyToInitialFetch
          :queryParams="{
            ...this.taskQueryParams,
            alwaysIncludeIds: [this.localTask?.id],
          }"
          hide-details
          autofocus
          activate-on-focus
          return-object
          @created="onTaskCreated"
        />
      </div>
    </AppContextMenu>
  </div>
</template>

<script>
export default {
  props: {
    taskQueryParams: Object,
  },
  data() {
    return {
      task: null,
      //selectedItem is the parent item of the task. For example project or product
      selectedItem: null,
    };
  },
  computed: {
    localTask: {
      get() {
        return this.task;
      },
      set(task) {
        this.task = task;
        this.$emit("task:change", { taskId: task?.id, selectedItem: this.selectedItem });
        if (task?.id) {
          this.closeTaskPickerMenu();
        }
      },
    },
  },
  methods: {
    onTaskCreated({ task }) {
      this.localTask = task;
    },
    closeActionMenu() {
      return this.$refs.actionMenu.close();
    },
    closeTaskPickerMenu() {
      this.$refs.taskPickerMenu.close();
    },
    onOpenTask() {
      this.closeActionMenu();
      const routeData = this.$router.resolve({
        name: this.$routeNames.TASK_VIEW.ROOT,
        params: { taskId: this.task?.id },
      });
      window.open(routeData.href, "_blank");
    },
    onChangeTaskClick({ pointerEvent }) {
      const { x, y } = this.closeActionMenu();
      this.openTaskPickerMenu(
        { pointerEvent, x, y },
        { task: this.task, selectedItem: this.selectedItem },
      );
    },
    onClearTask() {
      this.localTask = null;
    },
    openTaskPickerMenu({ pointerEvent, x, y }, { task, selectedItem }) {
      this.task = task;
      this.selectedItem = selectedItem;
      this.$refs.taskPickerMenu.open({ pointerEvent, x, y });
    },
    openActionMenu({ pointerEvent, x, y }, { task, selectedItem }) {
      this.task = task;
      this.selectedItem = selectedItem;
      this.$refs.actionMenu.open({ pointerEvent, x, y });
    },
  },
};
</script>

<style>
.height-40 {
  height: 40px;
}
.height-42 {
  height: 42px;
}
</style>
