<template>
  <div>
    <AppDataTable
      v-model="localSelectedStorages"
      :loading="isLoading"
      :headers="_headers"
      :items="storages"
      :noDataText="$t('common.noStorage')"
      :height="dataTableHeight"
      disable-pagination
      @toggle-select-all="onToggleSelectAll"
      show-select
    >
      <template
        v-slot:[`header.data-table-select`]
        v-if="storageTypesNotAllowedToSelect?.length"
      >
        <v-simple-checkbox
          :class="{ 'v-card--disabled': !selectableStorages?.length }"
          :disabled="!selectableStorages?.length"
          :value="isAllSelectableStoragesSelected"
          :indeterminate="isIndeterminate"
          @input="onToggleSelectAll"
        />
      </template>
      <template v-slot:item="{ item, isSelected, select }">
        <AppStorageTableItem
          :storage="item"
          :isSelected="isSelected"
          :storageTypesNotAllowedToSelect="storageTypesNotAllowedToSelect"
          @isSelected:change="select"
          @name:click="onNameClick"
          @menu:click="onMenuClick"
        />
      </template>
    </AppDataTable>
    <AppStorageContextMenu
      ref="contextMenu"
      :selectedStorages="selectedStorages"
      :showBoligmappa="showBoligmappa"
      :folderRouterName="folderRouterName"
      :rootId="rootId"
      :contextMenuItemsToHide="contextMenuItemsToHide"
      @action:click="onContextMenuActionClick"
    />
  </div>
</template>

<script>
export default {
  props: {
    storages: Array,
    isLoading: Boolean,
    selectedStorages: Array,
    showBoligmappa: Boolean,
    folderRouterName: String,
    rootId: String,
    dataTableHeight: String,
    contextMenuItemsToHide: Object,
    storageTypesNotAllowedToSelect: Array,
  },
  model: {
    prop: "selectedStorages",
    event: "selectedStorages:change",
  },
  computed: {
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$t("fileManagement.list.name"),
          align: "start",
          value: "name",
          show: true,
        },
        {
          text: this.$t("fileManagement.list.owner"),
          align: "start",
          value: "storageOwner.firstName",
          show: true,
        },
        {
          text: this.$t("fileManagement.list.lastModified"),
          align: "start",
          value: "updatedAt",
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
    localSelectedStorages: {
      get() {
        return this.selectedStorages;
      },
      set(value) {
        this.$emit("selectedStorages:change", value);
      },
    },
    selectableStorages() {
      return this.storages.filter((s) => this.isSelectableStorage(s));
    },
    isAllSelectableStoragesSelected() {
      return (
        this.isStoragesSelected(this.selectableStorages) &&
        !!this.selectableStorages?.length
      );
    },
    isIndeterminate() {
      const selectedStoragesOnPage = this.localSelectedStorages.filter((s) =>
        this.storages.some((storage) => storage.id === s.id),
      );
      return selectedStoragesOnPage.length > 0 && !this.isAllSelectableStoragesSelected;
    },
  },
  methods: {
    isStoragesSelected(storages) {
      return storages.every((s) =>
        this.localSelectedStorages.some((file) => file.id === s.id),
      );
    },
    isSelectableStorage(storage) {
      return !this.storageTypesNotAllowedToSelect?.includes(storage.type);
    },
    onToggleSelectAll(e) {
      //use default logic if no storage types are not allowed to be selected
      if (!this.storageTypesNotAllowedToSelect?.length) return;

      const isAllSelectableStorageSelected = this.isStoragesSelected(
        this.selectableStorages,
      );

      if (isAllSelectableStorageSelected) {
        this.localSelectedStorages = this.localSelectedStorages.filter(
          (s) => !this.selectableStorages.includes(s),
        );
      } else {
        this.localSelectedStorages = this.localSelectedStorages.concat(
          this.selectableStorages,
        );
      }
    },
    onMenuClick({ event, storage }) {
      if (!this.isSelectableStorage(storage)) return;

      if (!this.localSelectedStorages.some((s) => s.id === storage.id)) {
        this.localSelectedStorages = [storage];
      }
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onNameClick({ storage }) {
      this.$emit("storage:click", { storage });
    },
    onContextMenuActionClick(e) {
      this.$emit("menuAction:click", e);
    },
  },
};
</script>
