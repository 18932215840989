<template>
  <div class="c-storage-search" v-show="show">
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteConfirmation.active"
      :title="$t('fileManagement.fileExplorer.deleteConfirmation.title')"
      :subtitle="$t('fileManagement.fileExplorer.deleteConfirmation.subTitle')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteSelectedStorageItems(selected)"
    />
    <AppMoveStorageDialog
      v-model="dialog.move.active"
      :selectedStorages="dialog.move.storage"
      :parentId="dialog.move.parentId"
      :permissionLevel="dialog.move.permissionLevel"
      @after:save="afterStorageMove"
    />
    <AppLinkFilesDialog
      v-model="dialog.file.link.active"
      :files="dialog.file.link.files"
      :parentId="dialog.file.link.parentId"
      @after:save="afterStorageLink"
    />

    <v-toolbar
      :class="[{ 'border-b': !isLoading }]"
      dense
      flat
      height="24"
      extensionHeight="44"
    >
      <div class="label--small" style="padding-top: 15px">
        {{ $t("fileManagement.search.navToolbar.title") }}
      </div>

      <template v-slot:extension>
        <v-checkbox
          :value="isAllSelected"
          @change="onSelectAllChange"
          :disabled="!canSelectAll"
          :indeterminate="isIndeterminate"
          @click.stop
          dense
          hide-details
          click.stop
          color="primary"
        />
        <span v-if="!isLoading">
          {{ $tc("fileManagement.search.navToolbar.results", results.length) }}
        </span>
        <v-progress-linear
          :active="isLoading"
          :indeterminate="isLoading"
          absolute
          bottom
        />
      </template>
    </v-toolbar>
    <div class="c-storage-search__content" id="searchSideNav">
      <template v-if="!isLoading">
        <template v-if="results.length > 0">
          <AppActionBar v-model="showActionBar" id="searchActionBar">
            <template v-slot:actions>
              <AppDefaultTooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="showMove"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize"
                    color="info"
                    text
                    icon
                    @click="onMove"
                    :disabled="
                      lockedStorageSelected ||
                      selectedPermissionLevels.hasSelectedMultiple
                    "
                  >
                    <v-icon>{{ $icons.LIGHT.FILE.FILE_EXPORT }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("fileManagement.actionBar.move") }}</span>
              </AppDefaultTooltip>
              <AppDefaultTooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="showLink"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize"
                    color="info"
                    text
                    icon
                    @click="onLink"
                    :disabled="
                      folderSelected || relationFileSelected || lockedStorageSelected
                    "
                  >
                    <v-icon>{{ $icons.LIGHT.FILE.FILE_LINKED }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("fileManagement.linkedFile.title") }}</span>
              </AppDefaultTooltip>

              <AppDefaultTooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="showDownload"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize"
                    color="info"
                    text
                    icon
                    @click="onDownload"
                    :loading="isDownloading"
                  >
                    <v-icon>{{ $icons.LIGHT.ACTION.DOWNLOAD }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("common.downloadFiles") }}</span>
              </AppDefaultTooltip>

              <AppDefaultTooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="showDelete"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize"
                    color="error"
                    text
                    icon
                    @click="onDelete"
                    :disabled="lockedStorageSelected"
                  >
                    <v-icon>{{ $icons.LIGHT.ACTION.DELETE }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("fileManagement.actionBar.delete") }}</span>
              </AppDefaultTooltip>
            </template>
          </AppActionBar>
          <div v-for="storageItem of results" :key="storageItem.id">
            <AppStorageItem
              item-click
              :item="storageItem"
              @item:click="onStorageItemClick"
            >
              <template v-slot:prepend="{ item, state }">
                <template v-if="item.isLoading">
                  <AppCircularLoader small />
                </template>
                <template v-else>
                  <AppStorageThumbnail :item="item" left>
                    <template v-if="state.hover || state.isSelected" v-slot:content>
                      <v-checkbox
                        class="ma-0"
                        v-if="state.hover || state.isSelected"
                        v-model="state.isSelected"
                        @change="toggleSelectedStorage(item)"
                        @click.stop
                        dense
                        hide-details
                        click.stop
                      />
                    </template>
                  </AppStorageThumbnail>
                </template>
              </template>
              <template v-slot:label="{ item }">
                <AppTextTruncateBlocking
                  class="pl-3"
                  :disabled="item.name.length <= tooltipNameLength"
                  :text="item.name"
                />
                <AppStorageItemInfoRow :storage="item" />
              </template>
            </AppStorageItem>
          </div>
        </template>
        <template v-else>
          <v-row class="fill-height w-100 ma-0" align-content="center" justify="center">
            <v-col class="subtitle-1 text-center pb-0" cols="12">
              <v-img
                contain
                max-height="100"
                src="https://go-digital.azureedge.net/assets/images/search-empty.png"
              />
            </v-col>
            <v-col class="subtitle-1 text-center pb-0" cols="12">
              <h2>{{ $t("fileManagement.search.searchEmpty.title") }}</h2>
            </v-col>
            <v-col class="subtitle-1 text-center" cols="12">
              {{ $t("fileManagement.search.searchEmpty.subTitle") }}
            </v-col>
          </v-row>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { storageMixins } from "@/helpers/mixins";
export default {
  mixins: [storageMixins],
  data() {
    return {
      tooltipNameLength: 25,
      showActionBar: false,
      dialog: {
        move: {
          active: false,
          permissionLevel: null,
          storage: [],
          parentId: null,
        },
        deleteConfirmation: {
          active: false,
        },
        file: {
          link: {
            active: false,
            files: [],
            parentId: null,
          },
        },
      },
    };
  },
  props: {
    show: Boolean,
    search: String,
    rootId: String,
    showMove: Boolean,
    showLink: Boolean,
    showDelete: Boolean,
    showDownload: Boolean,
    actionLeft: Boolean,
    actionRight: Boolean,
  },
  model: {
    prop: "show",
    event: "show:change",
  },
  computed: {
    ...mapGetters("auth", {
      domainId: "activeDomainId",
    }),
    ...mapGetters("storageSearch", {
      selected: "selectedItems",
      isAllSelected: "isAllSelected",
      isIndeterminate: "isIndeterminate",
      folderSelected: "folderSelected",
      selectedPermissionLevels: "selectedPermissionLevels",
      relationFileSelected: "relationFileSelected",
      lockedStorageSelected: "lockedStorageSelected",
    }),
    ...mapState("storageSearch", {
      results: (state) => state.items,
      isLoading: (state) => state.isLoading,
      isDownloading: (state) => state.isDownloading,
    }),
    canSelectAll() {
      return this.results.length > 0 ? true : false;
    },
  },
  watch: {
    search: {
      handler(newVal) {
        if (!newVal) newVal = "";
        this.getSearch(newVal, true);
      },
    },
    results: {
      handler(newVal, oldVal) {
        const selected = newVal.filter((v) => v.isSelected === true);
        if (selected.length > 0) {
          this.showActionBar = true;
          this.positionActionBar();
        } else {
          this.showActionBar = false;
        }
      },
      deep: true,
      immediate: true,
    },
    show: {
      handler(newVal) {
        if (newVal) {
          this.getSearch(this.search, true);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getSearch(search, keepSelected) {
      this.$store.dispatch("storageSearch/search", {
        search,
        keepSelected,
        rootId: this.rootId,
      });
    },
    onStorageItemClick(item) {
      this.$emit("item:click", item);
    },
    toggleSelectedStorage(item) {
      this.$store.commit("storageSearch/toggleSelectedStorage", {
        isSelected: !item.isSelected,
        items: [item],
      });
    },
    onSelectAllChange(e) {
      if (e) {
        this.$store.commit("storageSearch/toggleSelectedStorage", {
          isSelected: true,
          items: [...this.results.filter((x) => x.isSelected === false)],
        });
      } else {
        this.$store.commit("storageSearch/toggleSelectedStorage", {
          isSelected: false,
          items: [...this.results],
        });
      }
    },
    onMove(e) {
      if (!this.selectedPermissionLevels.hasSelectedMultiple) {
        const permissionLevel = this.selectedPermissionLevels.mostSelected;
        this.dialog.move.storage = this.selected;
        this.dialog.move.parentId = this.domainId;
        this.dialog.move.permissionLevel = permissionLevel;
        this.dialog.move.active = true;
      }
    },
    async afterStorageMove() {
      this.$emit("after:move", this.selected);
    },
    onDownload(e) {
      const ids = this.selected.map((m) => m.id);
      if (ids.length > 0) {
        this.downloadFiles(ids);
      }
    },
    downloadFiles(ids) {
      this.$store.dispatch("storageSearch/download", ids);
    },
    onDelete() {
      this.dialog.deleteConfirmation.active = true;
    },
    async deleteSelectedStorageItems(selected) {
      await this.$store.dispatch("storageSearch/deleteStorageItems", {
        items: selected,
        search: this.search,
      });
      this.$emit("after:delete", { selected });
    },
    onLink(e) {
      this.dialog.file.link.files = this.selected;
      this.dialog.file.link.parentId = this.domainId;
      this.dialog.file.link.active = true;
    },
    onLinkSelectedChange(selected) {
      this.dialog.file.link.selected.push(...selected);
    },
    afterStorageLink() {
      this.$emit("after:link", this.selected);
    },
    positionActionBar() {
      setTimeout(() => {
        const parent = document.getElementById("searchSideNav");
        const child = document.getElementById("searchActionBar");
        if (parent && child) {
          const parentPos = parent.getBoundingClientRect();
          const relativePos = {
            top: parentPos.top + 4,
            left: parentPos.left + 354,
          };
          if (this.actionLeft) {
            relativePos.top = parentPos.top + 4;
            relativePos.left = parentPos.left - 63;
          } else if (this.actionRight) {
            //keep default position.
          }
          child.style.top = `${relativePos.top}px`;
          child.style.left = `${relativePos.left}px`;
        }
      }, 100);
    },
  },
};
</script>
