var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"list_tr",class:_vm.highlightedClass,on:{"contextmenu":_vm.onMenuClick,"click":function($event){return _vm.select(!_vm.isSelected)}}},[_c('td',[_c('v-simple-checkbox',{attrs:{"color":"primary","value":_vm.isSelected},on:{"input":function($event){return _vm.select($event)}}})],1),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.STATUS))?_c('td',[_c('AppCustomStatusIcon',{attrs:{"model":"task","statusId":_vm.statusId,"loading":_vm.task?.isUpdatingStatus,"disabled":_vm.isDisabledStatus},on:{"click":(e) => _vm.$emit('status:click', { ...e, selectedItem: _vm.task })}})],1):_vm._e(),_c('td',[_c('div',{staticClass:"table-cell-content__breakable-long-text"},[_c('span',{staticClass:"underline-on-hover cursor-pointer d-inline-block",on:{"click":function($event){$event.stopPropagation();return _vm.onGoToTaskClick.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.task.name)+" ")])])]),_c('td',[_c('div',{staticClass:"d-flex justify-end"},_vm._l((_vm.countItems),function(countData,index){return (countData.count)?_c('AppCountChip',{key:index,class:index === 0 && countData.count ? '' : 'ml-2',attrs:{"count":countData.count > 99 ? '99+' : countData.count,"icon":countData.icon,"text":countData.text},on:{"click":function($event){return _vm.onCountChipClick(countData.to)}}}):_vm._e()}),1)]),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.RESPONSIBLE))?_c('td',[(_vm.task.member)?[_c('AppUserAvatar',{attrs:{"user":_vm.task.member,"show-name":"","truncate":22}})]:_vm._e()],2):_vm._e(),_c('td',[_vm._v(" "+_vm._s(_vm.$tc( "taskManagement.list.participantAmount", Array.isArray(_vm.task.collaborators) ? _vm.task.collaborators.length : 0, ))+" ")]),(!_vm.isProject && _vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.PROJECT))?_c('td',[(_vm.task.project)?_c('div',{staticClass:"table-cell-content__breakable-long-text"},[_c('AppActionText',{attrs:{"to":{
          name: _vm.$routeNames.PROJECT.VIEW,
          params: { projectId: this.task?.project?.id },
        }}},[_vm._v(" "+_vm._s(_vm.task.project.name)+" ")])],1):_vm._e()]):_vm._e(),_c('td',[_c('div',{staticClass:"table-cell-content__breakable--margin"},[(_vm.task.formItem)?_c('AppActionText',{attrs:{"to":{
          name: _vm.$routeNames.FORM_VIEW.ROOT,
          params: {
            formId: _vm.task.formItem.formId,
          },
          query: { formItemId: _vm.task.formItem.id },
        }}},[_vm._v(" "+_vm._s(_vm.task.formItem.name)+" ")]):_vm._e()],1)]),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.START_DATE))?_c('td',[(_vm.task.startDate)?[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(_vm._s(_vm.formatDate(_vm.task.startDate)))])])]:_vm._e()],2):_vm._e(),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.DUE_DATE))?_c('td',[(_vm.task.dueDate)?[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(_vm._s(_vm.formatDate(_vm.task.dueDate)))]),(_vm.repeat)?_c('div',[_c('AppRepeatIcon',{staticClass:"ml-3",staticStyle:{"padding-bottom":"2px"},attrs:{"small":""}})],1):_vm._e()])]:_vm._e()],2):_vm._e(),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onMenuClick}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.REGULAR.ACTION.MENU))])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }