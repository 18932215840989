<template>
  <div
    @click.stop="onClick"
    class="d-flex align-center"
    :class="disabled ? '' : 'cursor-pointer'"
  >
    <AppDefaultTooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-icon ref="icon" style="width: 20px !important" :color="color">
            {{ icon }}
          </v-icon>
        </div>
      </template>
      {{ name }}
    </AppDefaultTooltip>
    <div v-if="showName" class="pl-2 text-subtitle-2">
      {{ name }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    model: String,
    statusId: String,
    loading: Boolean,
    disabled: Boolean,
    showName: Boolean,
  },
  computed: {
    ...mapGetters("statuses", {
      getStatusByModelAndId: "getStatusByModelAndId",
      isLoadingStatusesForModel: "isLoadingStatusesForModel",
    }),
    isLoadingStatuses() {
      return this.isLoadingStatusesForModel(this.model);
    },
    status() {
      return this.getStatusByModelAndId({ model: this.model, id: this.statusId });
    },
    icon() {
      if (this.isLoadingStatuses || this.loading) {
        return this.$icons.SOLID.ACTION.LOADING;
      }
      if (!this.status) {
        return this.$icons.SOLID.ACTION.NO_STATUS;
      }
      return this.$icons.SOLID.ACTION.STATUS;
    },
    name() {
      if (!this.status) {
        return this.$t("statuses.noStatus");
      }
      return this.status?.name;
    },
    color() {
      if (this.loading) return "primary";
      if (!this.status) return "";
      return this.status?.color;
    },
  },
  methods: {
    onClick(pointerEvent) {
      if (this.disabled) return;
      let { x, y } = this.$refs.icon.$el.getBoundingClientRect();
      y += 30;

      this.$emit("click", { pointerEvent, x, y });
    },
  },
};
</script>
