<template>
  <tr class="list_tr" :class="highlightedClass" @contextmenu="onMenuClick">
    <td>
      <div class="table-cell-content__breakable-long-text">
        <span class="d-inline-block">
          {{ team.name }}
        </span>
      </div>
    </td>
    <td>
      <template v-if="team.owner">
        <AppUserAvatar :user="team.owner" show-name :truncate="27" />
      </template>
    </td>
    <td class="text-start text-no-wrap">
      {{
        $tc(
          "team.list.memberAmount",
          Array.isArray(team.members) ? team.members.length : 0,
        )
      }}
    </td>
    <td class="text-end">
      <v-btn icon @click="onMenuClick">
        <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    team: Object,
    isHighlighted: Boolean,
  },
  computed: {
    highlightedClass() {
      return this.isHighlighted ? "list_tr--selected" : "";
    },
  },
  data() {
    return {};
  },
  methods: {
    onTeamEdit() {
      this.$emit("team:edit", { team: this.team });
    },
    onTeamDelete() {
      this.$emit("team:delete", { team: this.team });
    },
    onMenuClick(event) {
      this.$emit("menu:click", { team: this.team, event });
    },
  },
};
</script>
