<template>
  <div>
    <AppProjectDialog
      v-model="dialog.updateProject"
      :data="selectedItem"
      :title="$t('project.updateProject.title')"
      :submitBtnText="$t('common.save')"
      @confirm="afterProjectUpdate"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteProject"
      :item="selectedItem"
      :title="$t('project.deleteProject.title')"
      :subtitle="$t('project.deleteProject.subTitle')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('project.deleteProject.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteProject"
    />
    <AppDataTableServerSidePagination
      :loading="isLoading"
      :headers="_headers"
      :items="projects"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noProjects')"
      :columnsToFreeze="columnsToFreeze"
      :groupHeaderColumnsToFreeze="1"
      disable-multi-select
    >
      <template v-slot:[`group.header`]="{ toggle, group, isOpen, headers }">
        <td :colspan="headers.length - 3" @click="toggle">
          <div class="d-flex align-center">
            <v-btn class="mr-4" icon small @click.stop="toggle">
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="4"></td>
      </template>
      <template v-slot:item="{ item, headers }">
        <AppProjectTableItem
          :project="item"
          :isHighlighted="isHighlighted(item)"
          :headers="headers"
          :tableModel="tableModel"
          @project:update="onProjectUpdate"
          @menu:click="onMenuClick"
          @status:click="onStatusClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppContextMenu ref="contextMenu" @input="isContextMenuOpen = $event">
      <AppMenuOpenBtn @click="onProjectOpen" />
      <AppMenuEditBtn @click="onProjectEdit" :disabled="!canEditSelectedItem" />
      <AppMenuCopyUrlBtn
        :route="{
          name: $routeNames.PROJECT.VIEW,
          params: { projectId: this.selectedItem?.id },
        }"
      />
      <AppMenuDeleteBtn @click="onProjectDelete" :disabled="!canEditSelectedItem" />
    </AppContextMenu>
    <AppChangeCustomStatusIconMenu
      ref="statusMenu"
      @status:change="onStatusChange"
      model="project"
    />
  </div>
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";

export default {
  mixins: [groupByMixins],
  props: {
    projects: Array,
    isLoading: Boolean,
    tableModel: String,
    serverItemsLength: Number,
  },
  data() {
    return {
      dialog: {
        updateProject: false,
        deleteProject: false,
      },
      selectedItem: null,
      isContextMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      hasProjectImportIntegrationEnabled: "hasProjectImportIntegrationEnabled",
    }),
    columnsToFreeze() {
      const isStatusGrouped = this.filtersMixins_dataTableOptions.groupBy?.includes(
        this.filtersMixins_dataFields.STATUS,
      );

      let columns = 1;

      if (!isStatusGrouped) {
        columns += 1;
      }

      if (this.hasProjectImportIntegrationEnabled) {
        columns += 1;
      }

      return columns;
    },

    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          value: this.filtersMixins_dataFields.STATUS,
          sortable: false,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.number")),
          value: this.filtersMixins_dataFields.NUMBER,
          show: this.hasProjectImportIntegrationEnabled,
          sortable: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.projectName")),
          value: this.filtersMixins_dataFields.NAME,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.customer")),
          value: this.filtersMixins_dataFields.CLIENT,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.projectManager")),
          value: this.filtersMixins_dataFields.OWNER,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.members")),
          value: this.filtersMixins_dataFields.MEMBERS,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.startDate")),
          value: this.filtersMixins_dataFields.START_DATE,
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
    canEditSelectedItem() {
      return this.selectedItem?.permissions?.canEdit;
    },
    ...mapGetters("statuses", {
      hasFetchedStatuses: "hasFetchedStatuses",
      getStatusesByModel: "getStatusesByModel",
    }),
  },
  methods: {
    isHighlighted(item) {
      const isCurrentItem = this.selectedItem === item;
      if (!isCurrentItem) return false;

      const isAnyDialogsOpen = Object.values(this.dialog).some((value) => value === true);

      return this.isContextMenuOpen || isAnyDialogsOpen;
    },
    onMenuClick({ event, project }) {
      this.selectedItem = project;
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      return group;
    },
    deleteProject(e) {
      this.$emit("project:delete", e);
    },
    onProjectDelete() {
      this.dialog.deleteProject = true;
    },
    onProjectEdit() {
      this.dialog.updateProject = true;
    },
    onProjectOpen() {
      this.$router.push({
        name: this.$routeNames.PROJECT.VIEW,
        params: { projectId: this.selectedItem.id },
      });
    },
    onStatusChange({ statusId, selectedItem }) {
      const body = { statusId };

      this.onProjectUpdate({ body, project: selectedItem });
    },
    onStatusClick({ pointerEvent, x, y, selectedItem }) {
      if (!selectedItem || selectedItem?.isUpdatingStatus) return;

      this.$refs.statusMenu.open({
        pointerEvent,
        x,
        y,
        data: { statusId: selectedItem?.statusId, selectedItem },
      });
    },
    onProjectUpdate({ body, project }) {
      this.$emit("project:update", { data: body, item: project });
    },
    afterProjectUpdate(e) {
      this.$emit("project:afterUpdate", e);
    },
    async getStatuses() {
      await this.$store.dispatch("statuses/getStatuses", { model: "project" });
    },
  },
  async mounted() {
    if (!this.hasFetchedStatuses("project")) {
      this.getStatuses();
    }
  },
};
</script>
