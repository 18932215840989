import { normalizeTimeEntries, normalizeTimeEntry } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const integrationApiUrl = process.env.VUE_APP_INTEGRATION_API_URL;
import i18n from "@/plugins/i18n";
import store from "@/store";
import { formatDataTableOptionsToQuery } from "@/helpers/util/dataTable";
import { shouldUpdateItems } from "@/helpers/util";
const CancelToken = axios.CancelToken;
let getTimeEntriesCancel;

const state = () => ({
  timeEntries: [],
  selectedTimeEntries: [],
  count: null,
  isLoading: false,
  isSelectingAll: false,
  isUpdating: false,
});

const getters = {
  timeEntryById: (state) => (id) => state.timeEntries.find((x) => x.id === id),
};

const actions = {
  async getTimeEntries({ commit }, { filters, dataTableOptions, projectId }) {
    if (getTimeEntriesCancel) getTimeEntriesCancel();
    commit("loading", true);

    const optionsQuery = formatDataTableOptionsToQuery(dataTableOptions);

    const params = {
      ...filters,
      ...optionsQuery,
    };

    if (projectId) {
      //TODO: This is fine for now but a potential improvement would be to hard code projectId into the filters based on routeParams.
      //If projectId is in routeParams then it should ALWAYS filter by project.
      //This would make the logic on tasks forms etc more simplistic as well. (Remember to fix up generateRaport as well)
      params.projectIds = [projectId];
    }

    return axios
      .get(`${apiUrl}time-entry`, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          getTimeEntriesCancel = c;
        }),
      })
      .then(({ data }) => {
        const { timeEntries, count } = data;

        const shouldUpdate = shouldUpdateItems(
          count,
          dataTableOptions?.page,
          dataTableOptions?.itemsPerPage,
        );

        if (shouldUpdate) {
          const normalizedTimeEntries = normalizeTimeEntries(timeEntries);
          commit("setTimeEntries", normalizedTimeEntries);
        }
        commit("setCount", count);
        commit("loading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
  async selectAllTimeEntries({ commit }, { filters }) {
    commit("setIsSelectingAll", true);

    const params = {
      ...filters,
    };
    const body = { params };

    return axios
      .get(`${apiUrl}time-entry`, body)
      .then(({ data }) => {
        const { timeEntries } = data;
        const normalizedTimeEntries = normalizeTimeEntries(timeEntries);
        commit("setSelectedTimeEntries", normalizedTimeEntries);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("setIsSelectingAll", false);
      });
  },
  async createTimeEntry({ commit, state }, { body }) {
    return axios
      .post(`${apiUrl}time-entry`, body)
      .then(({ data }) => {
        const extraTimeEntries = data?.extraTimeEntries;
        if (extraTimeEntries?.length > 0) {
          const normalizedExtraTimeEntries = normalizeTimeEntries(extraTimeEntries);
          for (const extraTimeEntry of normalizedExtraTimeEntries) {
            commit("addTimeEntry", extraTimeEntry);
          }
          commit("setCount", state.count + extraTimeEntries.length);
        }

        const timeEntry = data?.timeEntry;
        const normalizedTimeEntry = normalizeTimeEntry(timeEntry);
        commit("setCount", state.count + 1);
        commit("addTimeEntry", normalizedTimeEntry);
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || "Something went wrong...";
        throw new Error(errorMessage);
      });
  },
  async updateTimeEntries({ commit }, { ids, body }) {
    commit("setIsUpdating", true);

    if (typeof body?.status === "string") {
      commit("toggleUpdatingStatuses", {
        ids,
        isUpdatingStatus: true,
      });
    }

    return axios
      .put(`${apiUrl}time-entry`, { ids, ...body })
      .then(({ data }) => {
        const timeEntries = data?.timeEntries;
        const normalizedTimeEntries = normalizeTimeEntries(timeEntries);
        for (const timeEntry of normalizedTimeEntries) {
          commit("updateTimeEntry", timeEntry);
        }
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || "Something went wrong...";
        throw new Error(errorMessage);
      })
      .finally(() => {
        commit("setIsUpdating", false);
        commit("toggleUpdatingStatuses", {
          ids,
          isUpdatingStatus: false,
        });
      });
  },
  async deleteTimeEntries({ commit, state }, { ids }) {
    commit("loading", true);
    const body = { data: { ids } };
    return axios
      .delete(`${apiUrl}time-entry`, body)
      .then(({ data }) => {
        const { timeEntries, extraTimeEntries, parentTimeEntries } = data;

        if (extraTimeEntries?.length > 0) {
          for (const extraTimeEntry of extraTimeEntries) {
            commit("removeTimeEntry", extraTimeEntry.id);
          }
          commit("setCount", state.count - extraTimeEntries.length);
        }
        if (parentTimeEntries.length > 0) {
          for (const parentTimeEntry of parentTimeEntries) {
            commit("updateTimeEntry", parentTimeEntry);
          }
          // commit("setCount", state.count - parentTimeEntries.length);
        }
        for (const timeEntry of timeEntries) {
          commit("removeTimeEntry", timeEntry.id);
        }
        const countRemoved = timeEntries?.length;
        commit("setCount", state.count - countRemoved);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async updateTimeEntry({ commit, state }, { id, body }) {
    commit("setIsUpdating", true);

    if (typeof body?.status === "string") {
      commit("toggleUpdatingStatuses", {
        ids: [id],
        isUpdatingStatus: true,
      });
    }

    return axios
      .put(`${apiUrl}time-entry/${id}`, body)
      .then(({ data }) => {
        const extraTimeEntries = data?.extraTimeEntries;

        if (extraTimeEntries?.length > 0) {
          const normalizedExtraTimeEntries = normalizeTimeEntries(extraTimeEntries);

          for (const extraTimeEntry of normalizedExtraTimeEntries) {
            const existingTimeEntry = state.timeEntries.find(
              (entry) => entry.id === extraTimeEntry.id,
            );

            if (existingTimeEntry) {
              commit("updateTimeEntry", extraTimeEntry);
            } else {
              commit("addTimeEntryUnderParent", {
                timeEntry: extraTimeEntry,
                parentId: extraTimeEntry.parentId,
              });
            }
          }
        }

        const parentTimeEntry = data?.parentTimeEntry;
        if (parentTimeEntry) {
          const normalizedTimeEntry = normalizeTimeEntry(parentTimeEntry);
          commit("updateTimeEntry", normalizedTimeEntry);
        }

        const timeEntry = data?.timeEntry;
        const normalizedTimeEntry = normalizeTimeEntry(timeEntry);
        if (normalizedTimeEntry) {
          commit("updateTimeEntry", normalizedTimeEntry);
        } else {
          commit("removeTimeEntry", id);
        }
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || "Something went wrong...";
        throw new Error(errorMessage);
      })
      .finally(() => {
        commit("setIsUpdating", false);
        commit("toggleUpdatingStatuses", {
          ids: [id],
          isUpdatingStatus: false,
        });
      });
  },
  async sendToAccounting({ commit }, { ids }) {
    commit("setIsUpdating", true);

    store.dispatch("snackbar/snackbar", {
      show: true,
      color: "info",
      text: i18n.t("timeRegistration.accounting.snackbar.inProgress"),
      timeout: 10000,
    });
    return axios
      .post(`${integrationApiUrl}accounting/time-entry`, { ids })
      .then(({ data }) => {
        const hasErrors = data.error?.accounting?.length > 0;
        if (hasErrors) {
          store.dispatch("snackbar/snackbar", {
            show: true,
            color: "warning",
            timeout: 15000,
            text: i18n.t("timeRegistration.accounting.snackbar.partialCompletion"),
          });
        } else {
          store.dispatch("snackbar/snackbar", {
            show: true,
            color: "success",
            text: i18n.t("timeRegistration.accounting.snackbar.completed"),
            timeout: 10000,
          });
        }

        const timeEntries = data?.timeEntries;
        const normalizedTimeEntries = normalizeTimeEntries(timeEntries);
        for (const timeEntry of normalizedTimeEntries)
          if (timeEntry) {
            commit("updateTimeEntry", timeEntry);
          }
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || "Something went wrong...";
        throw new Error(errorMessage);
      })
      .finally(() => {
        commit("setIsUpdating", false);
      });
  },
  async deleteInAccounting({ commit }, { ids }) {
    const body = { data: { ids } };
    return axios
      .delete(`${integrationApiUrl}accounting/time-entry`, body)
      .then(({ data }) => {
        const hasErrors = data.error?.accounting?.length > 0;
        if (hasErrors) {
          store.dispatch("snackbar/snackbar", {
            show: true,
            color: "warning",
            timeout: 15000,
            text: i18n.t("timeRegistration.accounting.snackbar.deletePartialSuccess"),
          });
        } else {
          store.dispatch("snackbar/snackbar", {
            show: true,
            color: "success",
            text: i18n.t("timeRegistration.accounting.snackbar.deleteSuccess"),
            timeout: 10000,
          });
        }
        const timeEntries = data?.timeEntries;
        const normalizedTimeEntries = normalizeTimeEntries(timeEntries);
        for (const timeEntry of normalizedTimeEntries) {
          commit("updateTimeEntry", timeEntry);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async copyTimeEntries({ commit }, { body }) {
    return await axios.post(`${apiUrl}time-entry/copy`, body).catch((error) => {
      throw new Error(error);
    });
  },
  resetState({ commit }) {
    commit("setTimeEntries", []);
    commit("setSelectedTimeEntries", []);
    commit("setCount", null);
  },
};

const mutations = {
  setTimeEntries(state, timeEntries) {
    state.timeEntries = timeEntries;

    // update selected time entries if they are in selected time entries and in time entries
    if (state.selectedTimeEntries.length <= 0) return;
    const timeEntriesToAdd = timeEntries.filter((t) =>
      state.selectedTimeEntries.some((st) => st.id === t.id),
    );
    if (timeEntriesToAdd.length <= 0) return;
    const selectedTimeEntriesToKeep = state.selectedTimeEntries.filter(
      (st) => !timeEntriesToAdd.some((t) => t.id === st.id),
    );
    state.selectedTimeEntries = [...selectedTimeEntriesToKeep, ...timeEntriesToAdd];
  },
  setDataTableOptions(state, options) {
    if (!options || Object.keys(options).length <= 0) return;
    state.dataTableOptions = options;
  },
  setCount(state, count) {
    state.count = count;
  },
  setSelectedTimeEntries(state, selected) {
    state.selectedTimeEntries = selected;
  },
  addTimeEntry(state, timeEntry) {
    state.timeEntries.unshift(timeEntry);
  },
  addTimeEntryUnderParent(state, { timeEntry, parentId }) {
    const index = state.timeEntries.findIndex((entry) => entry.id === parentId);

    if (index !== -1) {
      state.timeEntries = [
        ...state.timeEntries.slice(0, index + 1),
        timeEntry,
        ...state.timeEntries.slice(index + 1),
      ];
    } else {
      state.timeEntries.unshift(timeEntry);
    }
  },
  updateTimeEntry(state, updatedTimeEntry) {
    const index = state.timeEntries.findIndex((x) => x.id === updatedTimeEntry.id);
    if (index !== -1) {
      state.timeEntries.splice(index, 1, updatedTimeEntry);
    }
    const selectedTimeEntryIndex = state.selectedTimeEntries.findIndex(
      (x) => x.id === updatedTimeEntry.id,
    );
    if (selectedTimeEntryIndex !== -1) {
      state.selectedTimeEntries.splice(selectedTimeEntryIndex, 1, updatedTimeEntry);
    }
  },
  toggleUpdatingStatuses(state, { ids, isUpdatingStatus }) {
    ids.forEach((id) => {
      const index = state.timeEntries.findIndex((timeEntry) => timeEntry.id === id);
      if (index !== -1) {
        state.timeEntries[index].isUpdatingStatus = isUpdatingStatus;
      }
    });
  },
  removeTimeEntry(state, timeEntryId) {
    const index = state.timeEntries.findIndex((x) => x.id === timeEntryId);
    if (index !== -1) {
      state.timeEntries.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsUpdating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
  setIsSelectingAll(state, isSelectingAll) {
    state.isSelectingAll = isSelectingAll;
  },
};

export const timeEntries = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
