<template>
  <div>
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteDialog"
      :item="selectedItem"
      :title="$t('cms.template.project.delete.title')"
      :subtitle="$t('cms.template.project.delete.subTitle')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="templateDelete"
    />
    <AppCopyItemsDialog
      v-model="dialog.copyDialog"
      :inItems="[selectedItem]"
      type="PROJECT_TEMPLATE"
      @submit="onTemplateCopy"
    />
    <AppDataTable
      :loading="isLoading"
      :headers="_headers"
      :items="templates"
      :noDataText="$t('common.noTemplates')"
    >
      <template v-slot:item="{ item }">
        <AppFormTemplateTableItem
          :template="item"
          :isHighlighted="isHighlighted(item)"
          hide-category
          @edit:click="onTemplateEditClick"
          @menu:click="onMenuClick"
        />
      </template>
    </AppDataTable>
    <AppContextMenu ref="contextMenu" @input="isContextMenuOpen = $event">
      <AppMenuEditBtn @click="onTemplateEditClick({ template: selectedItem })" />
      <AppMenuCopyBtn @click="onCopyClick" v-if="!isCms" />
      <AppMenuDeleteBtn @click="onFormDelete" />
    </AppContextMenu>
  </div>
</template>

<script>
export default {
  props: {
    templates: Array,
    isLoading: Boolean,
    isCms: Boolean,
  },
  data() {
    return {
      assignableUsers: [],
      isAssignableUsersLoading: false,
      assignableProjects: [],
      isAssignableProjectsLoading: false,
      selectedItem: null,
      dialog: {
        deleteDialog: false,
        copyDialog: false,
      },
      isContextMenuOpen: false,
    };
  },
  computed: {
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$t("cms.template.project.name"),
          value: "name",
          show: true,
        },
        {
          text: this.$t("common.status"),
          value: "isPublished",
          show: true,
        },
        // {
        //   text: this.$t("common.category"),
        //   value: "category.name",
        //   show: true,
        // },
        {
          text: this.$t("common.lastUpdated"),
          value: "updatedAt",
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
  },
  methods: {
    isHighlighted(item) {
      const isCurrentItem = this.selectedItem === item;
      if (!isCurrentItem) return false;

      const isAnyDialogsOpen = Object.values(this.dialog).some((value) => value === true);

      return this.isContextMenuOpen || isAnyDialogsOpen;
    },
    onMenuClick({ event, template }) {
      this.selectedItem = template;
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onTemplateEditClick({ template }) {
      this.$emit("template:editClick", { template });
    },
    onFormDelete() {
      this.dialog.deleteDialog = true;
    },
    onCopyClick() {
      this.dialog.copyDialog = true;
    },
    onTemplateCopy(items) {
      this.$emit("template:copy", { items });
    },
    templateDelete() {
      this.$emit("template:delete", { item: this.selectedItem });
    },
  },
};
</script>
